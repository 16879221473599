import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import Title from 'components/title';
import Kontakty from 'components/kontakty';
import FullWidth from 'components/fullwidth';
import obr from '../images/likvidace-02.jpg';

const Sluzba = ({ data }) => (
  <Layout>
    <FullWidth>
      <Head pageTitle={data.likvidaceJson.title} />
      <div className="c1c1">
        <div className="column">
          <Title as="h2" size="large">
            {data.likvidaceJson.title}
          </Title>

          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: data.likvidaceJson.content.childMarkdownRemark.html,
            }}
          />
        </div>
        <div className="column lp2">
          <img src={obr} alt="Likvidace ilustrace" />
        </div>
      </div>
      <div className="cenik">
        <h3 className="kontaktni-osoby">Ceník</h3>
        <ul>
          <li>
            <strong>Likvidace společností</strong>
            <em>
              12&#8239;000,–&#8239;Kč za všechny úkony spojené s&nbsp;likvidací
            </em>
          </li>
          <li>
            <strong>Dědictví (likvidační správce, správce pozůstalosti)</strong>
            <em>Odměna dle vyhlášky číslo 196/2001&#8239;Sb.</em>
          </li>
        </ul>
      </div>
      <Box>
        <h3 className="kontaktni-osoby">Kontaktní osoby</h3>
      </Box>
      <Kontakty items={data.likvidaceJson.kontakty} />
    </FullWidth>
  </Layout>
);

Sluzba.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Sluzba;

export const query = graphql`
  query LikvidaceQuery {
    likvidaceJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      kontakty {
        name
        occupation
        phone1
        phone2
        email
      }
    }
  }
`;
